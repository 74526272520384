import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import MicroSite from './components/MicroSite';
import WithAuthentication from './components/WithAuthentication';
import HeaderContainer from './components/header/HeaderContainer';
import { useAuthentication } from './components/provider/AuthenticationProvider';
import { useBundles } from './components/provider/BundlesProvider';
import Imprint from './routes/Imprint';
import NotFound from './routes/NotFoundPage';
import Overview from './routes/Overview';
import PrivacyPreferences from './routes/PrivacyPreferences';
import ProductInfo from './routes/ProductInfo';
import { CALLBACK_PATH, FURY_ROUTE_PREFIX } from './shared/config';
import WithProductInfo from './shared/product';
import './styles/fonts.css';
const App: React.FC = () => {
  const { pages, toolbarItems } = useBundles();
  const { tileConfigurations } = useBundles(); // TODO NF-4158: should not be here but used directly from context; But maybe leave for better testability?
  const { error } = useAuthentication();

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      <HeaderContainer />
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.route}
            path={`${page.route}/*`}
            element={
              <WithAuthentication>
                <WithProductInfo>
                  <MicroSite page={page} />
                </WithProductInfo>
              </WithAuthentication>
            }
          />
        ))}
        <Route
          path="/"
          element={
            <WithAuthentication>
              <WithProductInfo>
                <Overview toolbarItems={toolbarItems} tileConfigurations={tileConfigurations} />
              </WithProductInfo>
            </WithAuthentication>
          }
        />
        <Route path={`${CALLBACK_PATH}/*`} element={<Navigate to="/" replace />} />
        <Route path={`${FURY_ROUTE_PREFIX}/imprint/*`} element={<Imprint />} />
        <Route path={`${FURY_ROUTE_PREFIX}/privacy/*`} element={<PrivacyPreferences />} />

        <Route
          path={`${FURY_ROUTE_PREFIX}/info/*`}
          element={
            <WithAuthentication>
              <ProductInfo />
            </WithAuthentication>
          }
        />
        <Route
          path="/*"
          element={
            <WithAuthentication>
              <NotFound />
            </WithAuthentication>
          }
        />
      </Routes>
    </>
  );
};

export default App;
