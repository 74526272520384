import { fury } from '@netfleets/fms-sdk';
import { FuryOverviewTileChangeVisibilityEvent, FuryOverviewTileLoadedEvent, FuryTile } from '@netfleets/fms-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from '../../shared/logger';
import { TileConfiguration } from '../../shared/tileConfig';

export interface SortableFuryTile extends FuryTile {
  id: string;
  loading: boolean;
}

export const sortTiles =
  (tileConfigurations: TileConfiguration[]) =>
  (tiles: SortableFuryTile[]): SortableFuryTile[] => {
    return tileConfigurations
      .map((tileConfig) => {
        const tile = tiles.find((tile) => tile.id === tileConfig.id);
        return tile ? { ...tile, hidden: tileConfig.hidden ?? false } : null;
      })
      .filter(Boolean) as SortableFuryTile[];
  };

export interface UseTilesOptions {
  tiles?: SortableFuryTile[];
  tileConfigurations: TileConfiguration[];
}

const useTiles = ({ tileConfigurations, tiles: optionalTiles = [] }: UseTilesOptions) => {
  const [tiles, setTiles] = useState<SortableFuryTile[]>(optionalTiles);

  useEffect(() => {
    const handleChangeVisibilityEvent = (event: CustomEvent<FuryOverviewTileChangeVisibilityEvent>) => {
      if (event.detail.visibility === 'hide') {
        setTiles((tiles) => tiles.filter((t) => t.elementName !== event.detail.elementName));
      }
    };

    const changeVisibilityListener = { handleEvent: handleChangeVisibilityEvent };
    window.addEventListener(fury.FURY_OVERVIEW_TILE_CHANGE_VISIBILITY_EVENT, changeVisibilityListener);

    const handleTileLoadedEvent = (event: CustomEvent<FuryOverviewTileLoadedEvent>) => {
      updateTile(event.detail.elementName, { loading: false });
    };

    const tileLoadedListener = {
      handleEvent: handleTileLoadedEvent,
    };
    window.addEventListener(fury.FURY_OVERVIEW_TILE_LOADED_EVENT, tileLoadedListener);

    return () => {
      window.removeEventListener(fury.FURY_OVERVIEW_TILE_CHANGE_VISIBILITY_EVENT, changeVisibilityListener);
      window.removeEventListener(fury.FURY_OVERVIEW_TILE_LOADED_EVENT, tileLoadedListener);
    };
  }, []);

  const tileConfigOf = (elementName: string) => {
    return tileConfigurations.find((tile) => tile.elementName === elementName);
  };

  const addTile = (tile: FuryTile): void => {
    const tileConfig = tileConfigOf(tile.elementName);
    if (!tileConfig) logger.info('Invalid tile attempted to load: ', tile.elementName);

    setTiles((oldTiles) => [
      ...oldTiles,
      {
        ...tile,
        loading: true,
        id: tile.elementName,
        hidden: tileConfig && tileConfig.hidden ? tileConfig.hidden : false,
      },
    ]);
  };

  const updateTile = (elementName: string, config: Partial<SortableFuryTile>) => {
    setTiles((oldTiles) =>
      oldTiles.map((tile) =>
        tile.elementName === elementName
          ? {
              ...tile,
              ...config,
            }
          : tile
      )
    );
  };

  const getOrderToApply = useCallback(() => tileConfigurations, [tileConfigurations]);

  const sortedTiles = useMemo(() => sortTiles(getOrderToApply())(tiles), [tiles, getOrderToApply]);

  return {
    tiles: sortedTiles,
    setTiles,
    customTilesOrder: tileConfigurations,
    addTile,
  };
};

export default useTiles;
