import { fury } from '@netfleets/fms-sdk';
import {
  FuryApplicationLink,
  FuryBundleEvent,
  FuryHeaderItem,
  FuryPage,
  FuryToolbarItem,
  FuryUserHeaderItem,
} from '@netfleets/fms-types';
import { useLocalStorage } from '@netfleets/frontend-components';
import React, { createContext, useContext, useEffect, useState } from 'react';
import '../../App.css';
import { loadBundle } from '../../shared/bundle-loader';
import { BUNDLES, FURY_ROUTE_PREFIX } from '../../shared/config';
import { logBundle } from '../../shared/logger';
import { TileConfiguration, defaultTileConfigurations } from '../../shared/tileConfig';
import '../../styles/fonts.css';
import useSubscriptions from '../hooks/useSubscriptions';
import useTiles, { SortableFuryTile } from '../hooks/useTiles';
import { useAuthentication } from './AuthenticationProvider';
import { useUser } from './UserProvider';

const loadBundles = () => BUNDLES.forEach(loadBundle);

export interface IAppContext {
  pages: FuryPage[];
  tiles: SortableFuryTile[];
  tileConfigurations: TileConfiguration[];
  persistTileConfigurations: (tiles: TileConfiguration[]) => void;
  toolbarItems: FuryToolbarItem[];
  userHeaderItems: FuryUserHeaderItem[];
  headerItems: FuryHeaderItem[];
  applicationLinks: FuryApplicationLink[];
}

const BundlesContext = createContext<IAppContext>({
  pages: [],
  tiles: [],
  tileConfigurations: [],
  persistTileConfigurations: () => {},
  toolbarItems: [],
  userHeaderItems: [],
  headerItems: [],
  applicationLinks: [],
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const BundlesProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [pages, setPages] = useState<FuryPage[]>([]);
  const [toolbarItems, setToolbarItems] = useState<FuryToolbarItem[]>([]);
  const [headerItems, setHeaderItems] = useState<FuryHeaderItem[]>([]);
  const [userHeaderItems, setUserHeaderItems] = useState<FuryUserHeaderItem[]>([]);
  const [applicationLinks, setApplicationLinks] = useState<FuryApplicationLink[]>([]);
  const { hasFleetManagementPermission } = useUser();
  const { user } = useAuthentication();
  const { updateSubscriptions } = useSubscriptions();
  if (
    !localStorage.getItem('fury.tileConfigurationVersion') ||
    localStorage.getItem('fury.tileConfigurationVersion') !== '1'
  ) {
    localStorage.removeItem('fury.tileConfigurations');
    localStorage.setItem('fury.tileConfigurationVersion', '1');
  }

  const [tileConfigurations, persistTileConfigurations] = useLocalStorage('fury.tileConfigurations', {
    initialValue: defaultTileConfigurations,
  });
  // TODO NF-4158: remove later, but we should remove the unused key
  localStorage.removeItem('fury.tileOrder');

  const { tiles, addTile } = useTiles({ tileConfigurations });

  const addBundleFromEventDetails = (details: Partial<FuryBundleEvent>) => {
    logBundle(details);

    if (details.pages) {
      details.pages.forEach((page) => {
        const newPage: FuryPage = {
          ...page,
          route: `${FURY_ROUTE_PREFIX}/${page.route}`,
        };
        setPages((oldPages) => [...oldPages, newPage]);
      });
    }

    if (details.lobbyTiles) {
      details.lobbyTiles.forEach((lobbyTile) => {
        addTile(lobbyTile);
      });
    }

    if (details.toolbarItems) {
      details.toolbarItems.forEach((toolbarItem) => {
        setToolbarItems((oldToolbarItems) => [...oldToolbarItems, toolbarItem].sort((a, b) => a.index - b.index));
      });
    }

    if (details.userHeaderItems) {
      details.userHeaderItems.forEach((userHeaderItem) => {
        setUserHeaderItems((oldUserHeaderItems) =>
          [...oldUserHeaderItems, userHeaderItem].sort((a, b) => a.index - b.index)
        );
      });
    }

    if (details.headerItems) {
      details.headerItems.forEach((headerItem) => {
        setHeaderItems((oldItems) => [...oldItems, headerItem].sort((a, b) => a.index - b.index));
      });
    }

    if (details.applicationLinks) {
      details.applicationLinks.forEach((applicationLink) => {
        setApplicationLinks((oldApplicationLinks) =>
          [...oldApplicationLinks, applicationLink].sort((a, b) => a.index - b.index)
        );
      });
    }

    if (details.bundleId) {
      updateSubscriptions(details.bundleId, details.bundleLoadedSubscriptions);
    }
  };

  const addBundles: (event: CustomEvent<FuryBundleEvent>) => void = (event) => {
    addBundleFromEventDetails(event.detail);
  };

  useEffect(() => {
    const bundleLoadedListener = { handleEvent: addBundles };
    window.addEventListener(fury.FURY_BUNDLE_LOADED_EVENT, bundleLoadedListener);
    return () => {
      window.removeEventListener(fury.FURY_BUNDLE_LOADED_EVENT, bundleLoadedListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && hasFleetManagementPermission) {
      loadBundles();
    }
  }, [user, hasFleetManagementPermission]);

  return (
    <BundlesContext.Provider
      value={{
        pages,
        tiles,
        tileConfigurations,
        persistTileConfigurations,
        toolbarItems,
        userHeaderItems,
        headerItems,
        applicationLinks,
      }}
    >
      {children}
    </BundlesContext.Provider>
  );
};

export const useBundles = () => useContext(BundlesContext);

export default BundlesProvider;
