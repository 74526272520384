import {
  AuthenticationResult,
  BrowserAuthErrorCodes,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionRequiredAuthErrorCodes,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { isFeatureFlagSet } from '@netfleets/frontend-internal';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AZURE_MICROSOFT_AUTHENTICATION_NO_CONSENT_ERROR_REGEX, SESSION_LOGGING_FLAG } from '../../shared/config';
import { logger } from '../../shared/logger';
import { getSessionToken, removeSessionToken, setSessionToken } from '../../shared/session';

export interface User {
  objectId: string;
  preferred_username: string;
  idToken: string;
}

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

interface AuthenticationContext {
  user: User | null;
  signOut: () => void;
  error?: string;
}

// interval how often we check if the token needs a refresh
const INTERVAL_TO_CHECK_TOKEN_IN_SECONDS = 30;

// percent of token validity we start the token refresh
// e.g. if validity is 10min, PERCENT_TOKEN_VALIDITY_TO_REFESH_AT is 50 than we refresh after 5min
const PERCENT_TOKEN_VALIDITY_TO_REFESH_AT = 50;

const AuthenticationContext = createContext<AuthenticationContext>({
  user: null,
  signOut: () => {},
});

const debugLog = (text) => {
  if (isFeatureFlagSet(SESSION_LOGGING_FLAG)) {
    logger.info(text);
  }
};

const tokenNeedsRefresh = (claims: IdTokenClaims): boolean => {
  const secondsValid = claims.exp! - claims.iat!;
  const now = Math.floor(Date.now() / 1000);
  const secondsSinceIssued = now - claims.iat!;
  const percentTokenValidityPassed = (secondsSinceIssued * 100) / secondsValid;
  debugLog(
    `reached ${Math.floor(
      percentTokenValidityPassed
    )}% of token validity (${secondsSinceIssued} of ${secondsValid} seconds). Will refresh at ${PERCENT_TOKEN_VALIDITY_TO_REFESH_AT}%.`
  );

  return percentTokenValidityPassed > PERCENT_TOKEN_VALIDITY_TO_REFESH_AT;
};

export const handleRedirectPromiseCatcher = (error) => {
  if (AZURE_MICROSOFT_AUTHENTICATION_NO_CONSENT_ERROR_REGEX.test(error.message)) {
    window.location.reload();
  } else {
    console.error(error);
  }
};

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {
  const msalContext = useMsal();

  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | undefined>();

  const setActiveAccount = () => {
    if (!msalContext.instance.getActiveAccount() && msalContext.instance.getAllAccounts()[0]) {
      debugLog('setting active account');

      msalContext.instance.setActiveAccount(msalContext.instance.getAllAccounts()[0]);
    }
  };

  const setUserAndToken = (update: AuthenticationResult) => {
    msalContext.instance.setActiveAccount(update.account);
    const claims = update.idTokenClaims as IdTokenClaims;

    setUser({
      idToken: update.idToken,
      preferred_username: claims.preferred_username!,
      objectId: claims.sub!,
    });

    const secondsValid = claims.exp! - claims.iat!;
    debugLog(`storing new token. valid for ${secondsValid} seconds (${Math.floor(secondsValid / 60)} minutes)`);
    setSessionToken(update.idToken);
  };

  const registerForRedirectAfterLogin = () => {
    msalContext.instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        debugLog(`token received after redirect: ${tokenResponse}`);

        if (tokenResponse) {
          debugLog('storing user and token received from login redirect');
          setUserAndToken(tokenResponse);
        }
      })
      .catch((e) => {
        handleRedirectPromiseCatcher(e);
        setError(e.message);
      });
  };

  const recreateUserFromStorage = () => {
    const activeClaims = msalContext.instance.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    const storedToken = getSessionToken();
    if (activeClaims && storedToken) {
      debugLog('try to recreate user from local storage');
      refreshTokenIfNecessary();
      setUser({
        idToken: storedToken,
        preferred_username: activeClaims.preferred_username!,
        objectId: activeClaims.sub!,
      });
    }
  };

  const refreshTokenIfNecessary = () => {
    const currentAccount = msalContext.instance.getActiveAccount();
    const claims = currentAccount?.idTokenClaims as IdTokenClaims;

    if (currentAccount && claims && tokenNeedsRefresh(claims)) {
      debugLog('token refresh needed');
      msalContext.instance
        .acquireTokenSilent({
          scopes: [],
          account: currentAccount,
        })
        .catch(async (error) => {
          debugLog(`silent token acquisition failed: ${error}`);
          if (
            error.errorCode === InteractionRequiredAuthErrorCodes.consentRequired ||
            error.errorCode === InteractionRequiredAuthErrorCodes.interactionRequired ||
            error.errorCode === InteractionRequiredAuthErrorCodes.loginRequired ||
            error.errorCode === BrowserAuthErrorCodes.monitorWindowTimeout
          ) {
            debugLog('interaction required, going to redirect to login page');
            await msalContext.instance.acquireTokenRedirect({ scopes: [] });
          }
        });
    }
  };

  const registerForTokenRefreshedEvent = () =>
    msalContext.instance.addEventCallback(async (message: EventMessage) => {
      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        debugLog('storing user and token received from acquire token event');
        setUserAndToken(message.payload as AuthenticationResult);
      }
    });

  useEffect(() => {
    setActiveAccount();
    recreateUserFromStorage();
    registerForRedirectAfterLogin();

    const interval = window.setInterval(refreshTokenIfNecessary, INTERVAL_TO_CHECK_TOKEN_IN_SECONDS * 1000);

    const handlerId = registerForTokenRefreshedEvent();

    return () => {
      clearInterval(interval);
      if (handlerId) {
        msalContext.instance.removeEventCallback(handlerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    debugLog(`msal process state: ${msalContext.inProgress}`);
  }, [msalContext.inProgress]);

  useEffect(() => {
    debugLog(`msal accounts state: ${msalContext.accounts}`);
    debugLog(`msal active account: ${msalContext.instance.getActiveAccount()}`);
  }, [msalContext.accounts, msalContext.instance]);

  const signOut = async () => {
    removeSessionToken();
    await msalContext.instance.logoutRedirect({
      idTokenHint: (user && user.idToken) || undefined,
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        signOut,
        error,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
