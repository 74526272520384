import React from 'react';

interface State {
  hasError: boolean;
  error: Error;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
  state: State = {
    error: { name: '', message: '' },
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <p>An Error occurred, please try again later.</p>
          <p>When talking to the support, please provide these details:</p>
          <p>{this.state.error.message}</p>
          {this.state.error.stack && <p>{this.state.error.stack}</p>}
          <p>Sorry for the inconvenience.</p>
          <p>
            <a href="/">Back to Application</a>
          </p>
        </>
      );
    }

    return this.props.children;
  }
}
